/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.container {
  max-width: 1088px;
  padding: 0 2rem;
}
body {
  font-family: "IBMPlexSans-Light";
  font-size: 28px ;
  background-color: #fff;
  color: #012169;
}

a {
  color: #012169;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: #A2D8FF;;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #012169;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #49d6a9;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  #services .container {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 90px;
  transition: all 0.5s;
  z-index: 997;
  background: rgba(52, 59, 64, 0.9);
}

#header #logo h1 {
  font-size: 32px;
  margin: 0;
  line-height: 1;

  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

#header.header-transparent {
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  height: 70px;
}

#header.header-scrolled {
  background: rgba(0, 0, 0, 0.8);
  height: 70px;
  transition: all 0.5s;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  white-space: nowrap;
  padding: 10px 0 10px 24px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "IBMPlexSans-Regular";
  color: #A2D8FF;
  font-size: 22px;
  padding: 0 4px;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: 0.4px;
  position: relative;
  text-transform: uppercase;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  transform: scaleX(1);
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #A2D8FF;
}

.navbar .nav-link:hover>a {
  color: #fff  ;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #666666;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #012169;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 45px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
  .section-header .section-description {
    padding: 2rem 0;
   }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(77, 77, 77, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 71px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  background-color: rgba(52, 59, 64, 0.9);
  background-color: rgba(76, 76, 76, 1);
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 22px;
  color: #A2D8FF;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #A2D8FF;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #012169;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  
  background: url(../public/assets/banner.svg) top center;
  background-size: cover;
  position: relative;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
    height: 90vh;
  }
  .section {
    margin: 4rem 0;
  }
  .services-images {
    max-width: 1024px;
    margin-left: 0px;
  }

  #quote {
    padding: 6rem 0;
  }
  
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#hero h1 {
  margin: 30px 0 10px 0;
  font-family: "IBMPlexSerif-Bold";
  font-size: 80px;
  font-size: 62px;
  /* font-weight: 700; */
  /* text-transform: uppercase; */
  color: #fff;
  line-height: 90px;
  line-height: 72px;
  max-width: 850px;
}

@media (max-width: 768px) {
  #hero {
    height: 50vh;
  }
  #hero h1 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-device-width: 550px) and (max-device-width: 768px) { 
  #hero {
    height: 75vh;
  }
}
@media screen and (min-device-width: 550px) and (max-device-width: 768px) and (orientation: portrait){ 
  #hero {
    height: 45vh;
  }
}
#hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

#hero .btn-get-started {
  font-family: "IBMPlexSans-SemiBold";
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #012169;
  border: 2px solid #012169;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  overflow: hidden;
}

/* Sections Header
--------------------------------*/
.section-header .section-title {
  font-family: "IBMPlexSans-SemiBold";
  font-size: 28px;
  color: #012169;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
  padding: 4rem 0;
  padding: 4rem 0 1rem;
}

.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: #012169;
  font-family: "IBMPlexSans-Light";
  font-size: 28px;
  padding: 2rem 8rem;
  padding: 3rem 8rem 4rem;
  line-height: 37px;
}

#quote .section-header .section-title {
  font-family: "IBMPlexSerif-Bold";
  font-size: 40px ;
  color: #000;
  text-transform: none;
}
#quote .section-header .section-description {
  font-family: "IBMPlexSerif-Bold";
  font-size: 32px ;
  color: #000;
}
.contact-section-logo {
  padding: 0 !important;
}
.contact-section-sub-title {
  text-align: center;
  padding-top: 4rem;
}
.contact-section-phone{
  text-align: center;
  font-family: "IBMPlexSans-Bold";
  font-size: 22px;
  color: #012169;
}
.form-class {
  margin: 0 auto;
}

.flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.successMsg-class {
  margin: 0 auto;
  border: 2px solid #A2D8FF;
  display: none;
  transform: rotateY(180deg);
}
.flip-card:hover .flip-card-inner {
  /* transform: rotateY(180deg); */
}
.error{
  text-align: center;
    color: red;
    font-size: 16px;
    margin-top: 1rem;
    display: none;
}
#successMsgText {
  padding: 3rem 7rem 4rem;
}
.solution-images{
  gap: 20px 24px;
  display: flex;
  padding: 1rem;
  padding-bottom: 10rem;
}
.solution-bg {
  background-color: #3F86FA;
  color: #012169;
  border-radius: 8px 153px 153px 8px;
  border-radius: 153px;
  font-family: "IBMPlexSans-SemiBold";
  font-size: 22px;
  width: calc(51% - 1rem);
  width: auto;
  padding: 0 1rem;
}
.solution-bg2 {
  background-color: #3F86FA;
  color: #012169;
  border-radius: 8px 153px 153px 8px;
  border-radius: 153px;
  font-family: "IBMPlexSans-SemiBold";
  font-size: 22px;
  /* width: calc(25% - 1rem); */
  width: auto;
  padding: 0 1rem;
}
.solution-bg3 {
  background-color: #3F86FA;
  color: #012169;
  border-radius: 8px 153px 153px 8px;
  border-radius: 153px;
  font-family: "IBMPlexSans-SemiBold";
  font-size: 22px;
  width: calc(31% - 1rem);
  width: auto;
  padding: 0 1rem;
}

.solution-bg-fade1 {
  background-color: #33B1FB;
  color: #fff;
}
.solution-bg-fade2 {
  background-color: #A2D8FF;
  color: #012169;
}
.solution-bg-fade3 {
  background-color: #3F86FA;
  color: #fff;
}
.solution-bg-fade4 {
  background-color: #C7E7FF;
  color: #012169;
}
.solution-bg-fade5 {
  background-color: #fff;
  color: #012169;
}
.solution-bg-fade6 {
  background-color: #63BEFF;
  color: #fff;
}

.solution-bg-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    text-align: center;
    margin: 0 auto;
}
.solution-bg .box, .solution-bg2 .box, .solution-bg3 .box  {
  display: flex;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.70rem 1rem;
  padding: 0.70rem 0.6rem;
}
.solution-bg .box div, .solution-bg2 .box div, .solution-bg3 .box div {
  margin: 0 auto;
  margin: auto 5px;
}
.services-images {
  padding-bottom: 6rem;
  text-align: center;
  row-gap: 2rem;
  justify-content: center;
  /* background-color: #f5f5f5; */
}
.form-group{
  display: flex;
  /* column-gap: 25px; */
  font-family: "IBMPlexSans-Medium";
  font-size: 20px;
  color: #012169;
  border: 2px solid #A2D8FF;
  /* border-width: 0px 0 2px  0; */
}
.form-group.mt-3 {
  margin-top: 0rem !important;
}
.form-label {
  width: 24%;
  padding: 0.5rem;
  word-wrap: break-word;
}
input[type=text],input[type=text]::placeholder{
  /* color: #012169; */
}
input[type=email],input[type=email]::placeholder{
  /* color: #012169; */
}

textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder { /* Modern browsers */
  color: #012169 ;
 }

 input:-webkit-autofill,
 input:-webkit-autofill:focus {
       background-color: transparent;
 }

.my-3 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.map-image{
  display: contents;
}

.contact-divider {
  background-color: #F5F5F5;
  height: 3.25rem;
  margin-bottom: 3rem;
}
.sec-one-desc {
  background-color: #f5f5f5;
  margin-bottom: 0;
  padding-bottom: 5rem;
}

#services {
  background-color: #f5f5f5;
}

#contact-footer {
  border-top: solid 50px #f5f5f5;
}

#submit {
  background: #012169;
  border: 0;
  color: #fff;
  font-family: IBMPlexSans-Bold;
  font-size: 21px;
  padding: 10px 75px;
  transition: .4s;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: whitesmoke;
  min-height: 40px;
  margin-top: 92px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
  .section-header .section-description {
    text-align: center;
    font-size: 20px;
    padding: 2rem 0;
    padding: 2rem 1rem;
    line-height: 28px;
  }
  .justify-content-center {
    row-gap: 2rem;
  }
  .form-label {
    min-width: 27%;
  }
  #successMsgText {
    padding: 3rem 1rem 4rem;
  }
}


/* Contact Section
--------------------------------*/
#contact {
  padding: 10px 0 40px 0;
}

#contact #google-map {
  height: 300px;
  margin-bottom: 20px;
}

#contact .info {

}

#contact-footer .info img {
  font-size: 32px;
  color: #012169;
  float: left;
  line-height: 0;
}

#contact-footer .info p {
  padding: 0 0 10px 50px;
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 18px;
}

#contact .info .email p {
  padding-top: 5px;
}

#contact .social-links {
  padding: 20px 0;
}

#contact .social-links a {
  font-size: 18px;
  background: #333;
  color: #fff;
  line-height: 1;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#contact .social-links a i {
  line-height: 0;
}

#contact .social-links a:hover {
  background: #012169;
  color: #fff;
}

#contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

#contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #012169;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

#contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #012169;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

#contact .php-email-form input,
#contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 22px;
  font-family: "IBMPlexSans-Bold";
  color: #012169;

  outline: none;
  border: none;
  /* border-left: 2px solid #A2D8FF; */
}

#contact .php-email-form input::focus,
#contact .php-email-form textarea::focus {
  background-color: #012169;
  color: #012169;
}

#contact .php-email-form button[type=submit] {
  background: #012169;
  border: 0;
  padding: 10px 75px;
  color: #fff;
  transition: 0.4s;
  font-size: 21px;
  font-family: "IBMPlexSans-Bold";
}

#contact .php-email-form button[type=submit]:hover {
  background: #11368d;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  padding: 30px 0;
  font-size: 18px;
  margin-top: 3rem;
}

#footer .copyright {
  text-align: center;
}

.services-images .box{
  position: relative;
}
.services-images-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 2.3rem;
  border: 0px solid;
  line-height: 30px;
  font-size: 22px;
  color: #012169;
  font-family: "IBMPlexSans-SemiBold";
  width: max-content;
  padding: 0 1rem;
}
@media screen and (max-width: 767px) {
  .solution-images{
    padding: 1rem;
    padding-bottom: 7rem;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0 auto;
  }
  
  .solution-bg, .solution-bg2, .solution-bg3 {
    width: unset;
    padding: 0 1rem;
    font-size: 20px;
  }
  .solution-bg-container {
    display: contents;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 900px) { 
  .solution-images {
    gap: 32px 35px;
  }
  .solution-bg-ipad{
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    row-gap: 2rem;
  }
  .solution2-bg-ipad{
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
  }

  .solution-bg, .solution-bg2, .solution-bg3 {
    width: auto;
    padding: 0 2rem;
  }
}